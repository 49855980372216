import React, {Dispatch, FC, SetStateAction} from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import {bookFormActions, bookFormActionTypes, part2, part3} from "../hooks/useBookForm";

type Props = {
    currentStep: number,
    body: part3,
    handleChange: Dispatch<bookFormActions>,
};

export const Step3: FC<Props> = (props) => {
    if (props.currentStep !== 3) {
        return null;
    }

    return (
        <>
            <p>We recommend creating a secure password for your account</p>
            <FormGroup>
                <Label for="password">Password</Label>
                <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter your Password"
                    value={props.body.password} // Prop: The email input data
                    onChange={event => props.handleChange({
                        type: bookFormActionTypes.EDIT_PART3,
                        payload: {
                            ...props.body,
                            password: event.currentTarget.value
                        }
                    })} // Prop: Puts data into the state
                />
            </FormGroup>
        </>
    );
};
