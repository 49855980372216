import React, {Dispatch, FC} from "react";
import {FormGroup, Input, Label} from "reactstrap";
import {bookFormActions, bookFormActionTypes, part1} from "../hooks/useBookForm";

type Props = {
    currentStep: number,
    body: part1,
    handleChange: Dispatch<bookFormActions>,
};

export const Step1: FC<Props> = (props) => {
    if (props.currentStep !== 1) {
        return null;
    }

    return (
        <>
            <p>How can we reach you?</p>
            <FormGroup>
                <Label for="email">Email</Label>
                <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Enter your Email"
                    value={props.body.email} // Prop: The email input data
                    onChange={event => props.handleChange({
                        type: bookFormActionTypes.EDIT_PART1,
                        payload: {
                            ...props.body,
                            email: event.currentTarget.value
                        }
                    })} // Prop: Puts data into the state
                />
            </FormGroup>
        </>
    );
};
