import React, {Dispatch, FC} from "react";
import { FormGroup, Label, Input } from "reactstrap";
import {bookFormActions, bookFormActionTypes, part2} from "../hooks/useBookForm";

type Props = {
    currentStep: number,
    body: part2,
    handleChange: Dispatch<bookFormActions>,
};

export const Step2: FC<Props> = (props) => {
    if (props.currentStep !== 2) {
        return null;
    }

    return (
        <>
            <p>What should we call you?</p>
            <FormGroup>
                <Label for="username">Username</Label>
                <Input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Enter your Username"
                    value={props.body.username} // Prop: The email input data
                    onChange={event => props.handleChange({
                        type: bookFormActionTypes.EDIT_PART2,
                        payload: {
                            ...props.body,
                            username: event.currentTarget.value
                        }
                    })} // Prop: Puts data into the state
                />
            </FormGroup>
        </>
    );
};
