import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { useRegister } from '../../hooks/useRegister';

export const Register: FC = () => {
    const {
        email,
        username,
        password,
        setUsername,
        setPassword,
        setEmail,
        usernameError,
        passwordErrors,
        emailError,
        isAuthenticated,
        registerHandler,
    } = useRegister();

    if (isAuthenticated) {
        return <Redirect to='/' />;
    }

    const formattedPasswordErrors = (
        <>
            {passwordErrors.map((e) => (
                <>
                    {e}
                    <br />
                </>
            ))}
        </>
    );

    return (
        <Container sx={{ marginTop: '10vh' }}>
            <Grid container item spacing={4}>
                <Grid container item>
                    <Typography variant='h4'>Register</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='email'
                        label='Email'
                        variant='outlined'
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        error={!!emailError}
                        helperText={emailError}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='username'
                        label='Username'
                        variant='outlined'
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                        error={!!usernameError}
                        helperText={usernameError}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id='password'
                        label='Password'
                        variant='outlined'
                        type='password'
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        error={passwordErrors.length > 0}
                        helperText={passwordErrors.length > 0 ? formattedPasswordErrors : ''}
                    />
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item>
                        <Button onClick={registerHandler} variant='contained'>
                            Register{' '}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Link to='/login'>Cancel</Link>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
