import { axios } from './axios';
import { ErrorFactory } from './errorFactory';
import { User } from './types';

interface AuthService {
    login: (username: string, password: string) => Promise<User>;
    register: (username: string, email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    renewToken: () => Promise<User>;
}

export const authService: AuthService = class {
    public static async login(username: string, password: string): Promise<User> {
        try {
            const res = await axios.post('/auth/login', {
                username,
                password,
            });
            this.updateAuthHeader(res.data.accessToken);
            return res.data.user;
        } catch (err) {
            throw ErrorFactory.get(err);
        }
    }

    public static async register(username: string, email: string, password: string) {
        try {
            await axios.post('/auth/register', {
                username,
                email,
                password,
            });
        } catch (err) {
            throw ErrorFactory.get(err);
        }
    }

    public static async logout() {
        try {
            await axios.post('/auth/logout');
            this.updateAuthHeader('');
        } catch (err) {
            throw ErrorFactory.get(err);
        }
    }

    public static async renewToken(): Promise<User> {
        try {
            const res = await axios.post('/auth/renew-token');
            this.updateAuthHeader(res.data.accessToken);
            return res.data.user;
        } catch (err) {
            throw ErrorFactory.get(err);
        }
    }

    private static updateAuthHeader(accessToken: string) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
};
