import React, { FC, ReactNode } from 'react';

import { Loading } from '../components/Loading';
import { Account } from '../pages/authenticated/Account';
import { Book } from '../pages/unauthenticated/Book';
import { paths } from './paths';

export interface IRoute {
    // Path, like in basic prop
    path: string;
    // Exact, like in basic prop
    exact: boolean;
    // Preloader for lazy loading
    fallback: NonNullable<ReactNode> | null;
    // component
    component?: FC;
    // Sub routes
    routes?: IRoute[];
    // Redirect path
    redirect?: string;
    // If router is private, this is going to be true
    private?: boolean;
}

export const routes: IRoute[] = [
    {
        path: '/',
        exact: true,
        redirect: paths.BOOK,
        fallback: <Loading />,
    },
    {
        path: paths.BOOK,
        component: Book,
        exact: true,
        private: true,
        fallback: <Loading />,
    },
    {
        path: paths.ACCOUNT,
        component: Account,
        exact: true,
        private: true,
        fallback: <Loading />,
    },
];
