import { useCallback, useContext } from 'react';

import { authService } from '../api/auth';
import { AuthContext, AuthContextType } from '../context/AuthContext';

export type tmsAuth = {
    isAuthenticated: boolean;
    login: (username: string, password: string) => Promise<void>;
    register: (username: string, email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    renewToken: () => Promise<void>;
};

export const useAuth = (): tmsAuth => {
    const { isAuthenticated, setIsAuthenticated, setUser } = useContext(
        AuthContext,
    ) as AuthContextType;

    const login = useCallback(
        async (username: string, password: string) => {
            const user = await authService.login(username, password);
            setIsAuthenticated(true);
            setUser(user);
        },
        [setIsAuthenticated, setUser],
    );

    const register = async (username: string, email: string, password: string) => {
        await authService.register(username, email, password);
    };

    const logout = useCallback(async () => {
        await authService.logout();
        setIsAuthenticated(false);
        setUser(null);
    }, [setIsAuthenticated, setUser]);

    const renewToken = useCallback(async () => {
        const user = await authService.renewToken();
        setIsAuthenticated(true);
        setUser(user);
    }, [setIsAuthenticated, setUser]);

    return {
        isAuthenticated,
        login,
        register,
        logout,
        renewToken,
    };
};
