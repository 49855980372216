import { useEffect, useState } from 'react';

import { ServiceError } from '../api/errorFactory';
import { validateEmail, validatePassword } from '../util/validators';
import { useAuth } from './useAuth';

export const useRegister = () => {
    const { isAuthenticated, register, login } = useAuth();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (isLoading) {
                let isValid = true;
                if (username === '') {
                    setUsernameError('Username cannot be empty');
                    isValid = false;
                }

                const passwordResults = validatePassword(password);
                if (!passwordResults.valid) {
                    setPasswordErrors([...passwordResults.details.map((d) => d.message)]);
                    isValid = false;
                }

                if (!validateEmail(email)) {
                    setEmailError('Invalid email');
                    isValid = false;
                }

                if (!isValid) {
                    setIsLoading(false);
                    return;
                }

                try {
                    await register(username, email, password);
                    await login(username, password);
                } catch (err) {
                    const statusCode = (err as ServiceError).statusCode;
                    if (statusCode === 409) {
                        setUsernameError('Username already registered');
                    } else if (statusCode === 400) {
                        console.log((err as ServiceError).message);
                    }
                    setIsLoading(false);
                }
            }
        })();
    }, [isLoading]);

    useEffect(() => {
        setPasswordErrors([]);
    }, [password]);

    useEffect(() => {
        setEmailError('');
    }, [email]);

    useEffect(() => {
        setUsernameError('');
    }, [username]);

    const registerHandler = () => setIsLoading(true);

    return {
        email,
        username,
        password,
        setUsername,
        setPassword,
        setEmail,
        usernameError,
        passwordErrors,
        emailError,
        isAuthenticated,
        registerHandler,
    };
};
