import { language, locale } from './constants';

export function mapToLocales(language: language): locale {
    switch (language) {
        case 'French':
            return 'fr';
        case 'Portugese (Brazil)':
            return 'pt';
        case 'Spanish':
            return 'es';
    }
}

export function capitalize(s: string): string {
    if (s.length < 2) {
        return s;
    }
    return s.charAt(0).toLocaleUpperCase() + s.slice(1);
}
