import React, {FC} from "react";
import "./MultiStepProgressBar.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";

type Prop = {
    currentStep: number
}

export const MultiStepProgressBar: FC<Prop> = (props) => {
    let stepPercentage = 0;

    if (props.currentStep === 1) {
        stepPercentage = 0;
    } else if (props.currentStep === 2) {
        stepPercentage = 50;
    } else if (props.currentStep === 3) {
        stepPercentage = 100;
    } else {
        stepPercentage = 0;
    }

    return (
        <ProgressBar percent={stepPercentage}>
        <Step>
            {({ accomplished, index}) => (
                <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>
                    {index + 1}
                </div>
            )}
        </Step>
        <Step>
            {({ accomplished, index }) => (
                <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>
                    {index + 1}
                </div>
            )}
        </Step>
        <Step>
            {({ accomplished, index }) => (
                <div className={`indexedStep ${accomplished ? "accomplished" : null}`}>
                    {index + 1}
                </div>
            )}
        </Step>
        </ProgressBar>
    );
};