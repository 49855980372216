import React, { FC } from "react";
import {
    Form,
    Button,
    Card,
} from "react-bootstrap";

import {MultiStepProgressBar} from "./MultiStepProgressBar";
import {Step1} from "./step1";
import {Step2} from "./step2";
import {Step3} from "./step3";
import {bookFormActionTypes, formStep, useBookForm} from "../hooks/useBookForm";


export const BookForm: FC = () => {
    const {
        state,
        dispatch,
        submitHandler
    } = useBookForm();

    return (
        <>
            <Form>
                <Card>
                    <Card.Header>Create an Account</Card.Header>
                    <Card.Body>
                        <Card.Title>
                            <MultiStepProgressBar currentStep={state.curStep} />
                        </Card.Title>
                        <Card.Text />
                        <Step1
                            currentStep={state.curStep}
                            handleChange={dispatch}
                            body={state.part1}
                        />
                        <Step2
                            currentStep={state.curStep}
                            handleChange={dispatch}
                            body={state.part2}
                        />
                        <Step3
                            currentStep={state.curStep}
                            handleChange={dispatch}
                            body={state.part3}
                        />
                    </Card.Body>
                    <Card.Footer>
                        {state.curStep > parseInt(Object.keys(formStep)[0]) &&
                        <Button color="secondary float-left" onClick={() => dispatch({
                            type: bookFormActionTypes.PREV
                        })}>
                            Previous
                        </Button>
                        }
                        {state.curStep < parseInt(Object.keys(formStep)[Object.keys(formStep).length / 2 - 1]) &&
                        <Button color="secondary float-right" onClick={() => dispatch({
                            type: bookFormActionTypes.NEXT
                        })}>
                            Next
                        </Button>
                        }
                        {state.curStep == parseInt(Object.keys(formStep)[Object.keys(formStep).length / 2 - 1]) &&
                        <Button color="secondary float-right" onClick={() => submitHandler()}>
                            Submit
                        </Button>
                        }

                    </Card.Footer>
                </Card>
            </Form>
        </>
    );
}
