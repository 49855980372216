import React, { createContext, FC, ReactNode, useState } from 'react';

import { User } from '../api/types';

export type AuthContextType = {
    isAuthenticated: boolean;
    user: User | null;
    setIsAuthenticated: (newState: boolean) => void;
    setUser: (newUser: User | null) => void;
};

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: FC<ReactNode> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    return (
        <AuthContext.Provider
            value={{
                user,
                isAuthenticated,
                setUser,
                setIsAuthenticated,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
