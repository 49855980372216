import { useEffect, useState } from 'react';

import { useAuth } from './useAuth';

export const useLogin = () => {
    const { isAuthenticated, login } = useAuth();
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (isLoading) {
                let isValid = true;
                if (username === '') {
                    setUsernameError('Username cannot be empty');
                    isValid = false;
                }

                if (password === '') {
                    setPasswordError('Password cannot be empty');
                    isValid = false;
                }

                if (!isValid) {
                    return;
                }

                try {
                    await login(username, password);
                } catch (err) {
                    setUsernameError('Username and password do not match');
                    setPasswordError('Username and password do not match');
                } finally {
                    setIsLoading(false);
                }
            }
        })();
    }, [isLoading]);

    const loginHandler = () => setIsLoading(true);
    return {
        username,
        password,
        setUsername,
        setPassword,
        usernameError,
        passwordError,
        isAuthenticated,
        loginHandler,
    };
};
