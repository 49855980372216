import React, {FC} from "react";
import {BookForm} from "../../components/BookForm";
import {Container, Row, Col } from 'react-bootstrap';
import './BookForm.css'

export const Book: FC = () => {
    return(
        <Container>
            <Row>
                <Col>
                    <BookForm />
                </Col>
            </Row>
        </Container>
    );
};