/* eslint-disable @typescript-eslint/no-explicit-any */
interface ErrorObject {
    response: {
        data: {
            statusCode: number;
            message: string;
        };
    };
}

interface IErrorFactory {
    get: (err: any) => Error;
}

export class ServiceError extends Error {
    readonly statusCode: number;
    readonly message: string;

    constructor(error: ErrorObject) {
        super();
        this.statusCode = error.response?.data?.statusCode;
        this.message = error.response?.data?.message;
    }
}

export const ErrorFactory: IErrorFactory = class {
    public static get(err: any) {
        if (err.response?.data) {
            return new ServiceError(err);
        } else {
            return new Error('Network Error');
        }
    }
};
