import Container from '@mui/material/Container';
import React, { FC } from 'react';

import { Header } from '../components/Header';
import Router from '../router/Router';
import { routes } from '../router/Routes';

export const Shell: FC = () => {
    const pages = routes.map((r) => {
        return {
            url: r.path,
            title: r.path.replace('/', ''),
        };
    });

    return (
        <>
            <Header pages={pages} />
            <Container sx={{ marginTop: '50px' }}>
                <Router routes={routes} />
            </Container>
        </>
    );
};
