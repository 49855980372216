import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { FC, useEffect, useState } from 'react';

import { accountFormActionTypes, useAccountForm } from '../../hooks/useAccountForm';

export const Account: FC = () => {
    const { state, dispatch, changePassword } = useAccountForm();
    const [openAlert, setOpenAlert] = useState(false);

    useEffect(() => {
        if (state.passwordChange.success) {
            setOpenAlert(true);
        }
    }, [state.passwordChange.success]);
    return (
        <>
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={() => setOpenAlert(false)}>
                <Alert
                    onClose={() => setOpenAlert(false)}
                    severity='success'
                    sx={{ width: '100%' }}
                >
                    Successfuly updated password
                </Alert>
            </Snackbar>
            <Grid container justifyItems={'center'} alignItems='center' spacing={8}>
                <Grid container item xs={12} spacing={4} direction='column'>
                    <Grid item xs={9}>
                        <Typography variant='h5'>Change Password</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            value={state.passwordChange.original}
                            fullWidth
                            type='password'
                            error={state.invalidCredentials}
                            helperText={state.invalidCredentials && 'Invalid password'}
                            onChange={(e) =>
                                dispatch({
                                    type: accountFormActionTypes.EDIT_ORIGINAL,
                                    payload: e.target.value,
                                })
                            }
                            placeholder='Old password'
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            value={state.passwordChange.new}
                            fullWidth
                            type='password'
                            error={state.passwordChange.error}
                            helperText={state.passwordChange.error && 'Passwords do not match'}
                            onChange={(e) =>
                                dispatch({
                                    type: accountFormActionTypes.EDIT_NEW,
                                    payload: e.target.value,
                                })
                            }
                            placeholder='New password'
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            value={state.passwordChange.confirmNew}
                            fullWidth
                            error={state.passwordChange.error}
                            helperText={state.passwordChange.error && 'Passwords do not match'}
                            type='password'
                            onChange={(e) =>
                                dispatch({
                                    type: accountFormActionTypes.EDIT_CONFIRM_NEW,
                                    payload: e.target.value,
                                })
                            }
                            placeholder='Confirm new password'
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <LoadingButton
                            size='small'
                            onClick={() => {
                                changePassword();
                            }}
                            loading={state.isLoading}
                            variant='contained'
                        >
                            Change Password
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
