import './App.css';

import Box from '@mui/material/Box';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Loading } from './components/Loading';
import { useAuth } from './hooks/useAuth';
import { NotFound } from './pages/NotFound';
import { Shell } from './pages/Shell';
import { Login } from './pages/unauthenticated/Login';
import { Register } from './pages/unauthenticated/Register';
import { Book } from './pages/unauthenticated/Book';
import { paths } from './router/paths';

const App: FC = () => {
  const { renewToken, isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    renewToken()
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isAuthenticated) {
      intervalId = setInterval(renewToken, 1000 * 60 * 14);
    }

    return () => {
      isAuthenticated && clearInterval(intervalId);
    };
  }, [renewToken, isAuthenticated]);

  const render = useCallback(() => {
    return isLoading ? (
        <Loading />
    ) : (
        <Box display='flex' minHeight='100%' width='100%' flexDirection='column'>
          <Switch>
            <Route path={paths.LOGIN} exact component={Login} />
            <Route path={paths.REGISTER} exact component={Register} />
            <Route path={paths.BOOK} exact component={Book} />
            <Route path='/404' component={NotFound} />
            <Route path='/' component={Shell} />
          </Switch>
        </Box>
    );
  }, [isLoading]);

  return render();
};

export default App;
