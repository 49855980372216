import {useEffect, useReducer} from "react";


export type part1 = {
    // TODO:
    email: string
};

export type part2 = {
    // TODO:
    username: string
};

export type part3 = {
    // TODO:
    password: string
};

type formErrors = {
    // TODO: add form error cases
};

export enum formStep {
    PART1 = 1,
    PART2 = 2,
    PART3= 3,
}

type bookFormState = {
    part1: part1;
    part2: part2;
    part3: part3;
    curStep: formStep;
    isLoading: boolean;
};

const initialFormState: bookFormState = {
    part1: {
        // TODO
        email: ''
    },
    part2: {
        // TODO
        username: ''
    },
    part3: {
        // TODO
        password: ''
    },
    curStep: formStep.PART1,
    isLoading: false
};

export enum bookFormActionTypes {
    EDIT_PART1 = 'EDIT_PART1',
    EDIT_PART2 = 'EDIT_PART2',
    EDIT_PART3 = 'EDIT_PART3',
    IS_LOADING = 'IS_LOADING',
    NEXT = "NEXT",
    PREV = "PREV"
    // TODO: Error action types
}

export type bookFormActions =
    | EDIT_PART1
    | EDIT_PART2
    | EDIT_PART3
    | IS_LOADING
    | NEXT
    | PREV;

type EDIT_PART1 = {
    type: bookFormActionTypes.EDIT_PART1;
    payload: part1;
}

type EDIT_PART2 = {
    type: bookFormActionTypes.EDIT_PART2;
    payload: part2;
}

type EDIT_PART3 = {
    type: bookFormActionTypes.EDIT_PART3;
    payload: part3;
}

type IS_LOADING = {
    type: bookFormActionTypes.IS_LOADING;
}

type NEXT = {
    type: bookFormActionTypes.NEXT;
}

type PREV = {
    type: bookFormActionTypes.PREV;
}

const reducer = (state: bookFormState, action: bookFormActions): bookFormState => {
    switch (action.type) {
        case bookFormActionTypes.EDIT_PART1:
            return {
                ...state,
                part1: action.payload,
                curStep: formStep.PART1
            };
        case bookFormActionTypes.EDIT_PART2:
            return {
                ...state,
                part2: action.payload,
                curStep: formStep.PART2
            };
        case bookFormActionTypes.EDIT_PART3:
            return {
                ...state,
                part3: action.payload,
                curStep: formStep.PART3
            };
        case bookFormActionTypes.NEXT:
            return {
                ...state,
                curStep: state.curStep+1
            };
        case bookFormActionTypes.PREV:
            return {
                ...state,
                curStep: state.curStep-1
            };
        case bookFormActionTypes.IS_LOADING:
            return {
                ...state,
                isLoading: !state.isLoading,
            };
        default:
            return state;
    }
};

export const useBookForm = () => {
    const [state, dispatch] = useReducer(reducer, initialFormState);

    useEffect(() => {
        if (state.isLoading){
            alert(`Your registration detail: \n 
              Email: ${state.part1.email} \n 
              Username: ${state.part2.username} \n
              Password: ${state.part3.password}`);

            dispatch({ type: bookFormActionTypes.IS_LOADING })
        }
    }, [state.isLoading])

    const submitHandler = () => dispatch({ type: bookFormActionTypes.IS_LOADING });
    return {
        state,
        dispatch,
        submitHandler
    };
}