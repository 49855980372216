const LOGIN = '/login';
const REGISTER = '/register';
const BOOK = '/book';
const ACCOUNT = '/account';
const UserInfo = '/userinfo';

export const paths = {
    LOGIN,
    REGISTER,
    BOOK,
    ACCOUNT,
    UserInfo
};
