import { axios } from './axios';
import { ErrorFactory } from './errorFactory';
import { User } from './types';

interface UserService {
    changePassword: (originalPassword: string, newPassword: string) => Promise<User>;
}

export const userService: UserService = class {
    public static async changePassword(
        originalPassword: string,
        newPassword: string,
    ): Promise<User> {
        try {
            const res = await axios.put('/users', {
                originalPassword,
                newPassword,
            });
            return res.data.user;
        } catch (err) {
            throw ErrorFactory.get(err);
        }
    }
};
