// 20.2 https://github.com/cyrusdiego/ECE493/issues/36
import { useCallback, useReducer } from 'react';

import { userService } from '../api/user';

type accountFormState = {
    invalidCredentials: boolean;
    passwordChange: {
        original: string;
        new: string;
        confirmNew: string;
        error: boolean;
        success: boolean;
    };
    isLoading: boolean;
};

const initialFormState: accountFormState = {
    invalidCredentials: false,
    passwordChange: {
        original: '',
        new: '',
        confirmNew: '',
        error: false,
        success: false,
    },
    isLoading: false,
};

export enum accountFormActionTypes {
    EDIT_ORIGINAL = 'EDIT_ORIGINAL',
    EDIT_NEW = 'EDIT_NEW',
    EDIT_CONFIRM_NEW = 'EDIT_CONFIRM_NEW',
    MISMATCH_PASSWORD = 'MISMATCH_PASSWORD',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    PASSWORD_UPDATED = 'PASSWORD_UPDATED',
    IS_LOADING = 'IS_LOADING',
}

export type accountFormActions =
    | EDIT_ORIGINAL
    | EDIT_NEW
    | EDIT_CONFIRM_NEW
    | MISMATCH_PASSWORD
    | INVALID_CREDENTIALS
    | PASSWORD_UPDATED
    | IS_LOADING;

type IS_LOADING = {
    type: accountFormActionTypes.IS_LOADING;
};

type EDIT_ORIGINAL = {
    type: accountFormActionTypes.EDIT_ORIGINAL;
    payload: string;
};

type EDIT_NEW = {
    type: accountFormActionTypes.EDIT_NEW;
    payload: string;
};

type EDIT_CONFIRM_NEW = {
    type: accountFormActionTypes.EDIT_CONFIRM_NEW;
    payload: string;
};

type MISMATCH_PASSWORD = {
    type: accountFormActionTypes.MISMATCH_PASSWORD;
};

type INVALID_CREDENTIALS = {
    type: accountFormActionTypes.INVALID_CREDENTIALS;
};

type PASSWORD_UPDATED = {
    type: accountFormActionTypes.PASSWORD_UPDATED;
};

const reducer = (state: accountFormState, action: accountFormActions): accountFormState => {
    switch (action.type) {
        case accountFormActionTypes.EDIT_NEW:
            return {
                ...state,
                passwordChange: {
                    ...state.passwordChange,
                    new: action.payload,
                    error: false,
                },
            };
        case accountFormActionTypes.EDIT_ORIGINAL:
            return {
                ...state,
                invalidCredentials: false,
                passwordChange: {
                    ...state.passwordChange,
                    original: action.payload,
                    error: false,
                },
            };
        case accountFormActionTypes.EDIT_CONFIRM_NEW:
            return {
                ...state,
                passwordChange: {
                    ...state.passwordChange,
                    confirmNew: action.payload,
                    error: false,
                },
            };
        case accountFormActionTypes.MISMATCH_PASSWORD:
            return {
                ...state,
                passwordChange: {
                    ...state.passwordChange,
                    error: true,
                },
            };
        case accountFormActionTypes.INVALID_CREDENTIALS:
            return {
                ...state,
                invalidCredentials: true,
            };
        case accountFormActionTypes.PASSWORD_UPDATED:
            return {
                ...state,
                passwordChange: {
                    ...state.passwordChange,
                    original: '',
                    new: '',
                    confirmNew: '',
                    success: true,
                    error: false,
                },
            };
        case accountFormActionTypes.IS_LOADING:
            return {
                ...state,
                isLoading: !state.isLoading,
            };
        default:
            return state;
    }
};

export const useAccountForm = () => {
    const [state, dispatch] = useReducer(reducer, initialFormState);

    const changePassword = useCallback(async () => {
        if (state.passwordChange.new !== state.passwordChange.confirmNew) {
            dispatch({ type: accountFormActionTypes.MISMATCH_PASSWORD });
            return;
        }

        try {
            dispatch({ type: accountFormActionTypes.IS_LOADING });
            await userService.changePassword(
                state.passwordChange.original,
                state.passwordChange.new,
            );
            dispatch({ type: accountFormActionTypes.PASSWORD_UPDATED });
        } catch (e) {
            dispatch({ type: accountFormActionTypes.INVALID_CREDENTIALS });
        } finally {
            dispatch({ type: accountFormActionTypes.IS_LOADING });
        }
    }, [state.passwordChange.original, state.passwordChange.new, state.passwordChange.confirmNew]);
    return {
        state,
        dispatch,
        changePassword,
    };
};
