import * as EmailValidator from 'email-validator';
import passwordValidator from 'password-validator';

const passwordSchema = new passwordValidator();

passwordSchema
    .is()
    .min(8)
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(2) // Must have at least 2 digits
    .has()
    .not()
    .spaces();

type passwordDetails = {
    validation: string;
    arguments?: number;
    message: string;
};
type validationResult = {
    valid: boolean;
    details: passwordDetails[];
};

export function validatePassword(password: string): validationResult {
    const results = passwordSchema.validate(password, { details: true }) as passwordDetails[];

    return {
        valid: results.length === 0,
        details: results,
    };
}

export function validateEmail(email: string): boolean {
    return EmailValidator.validate(email);
}
