
import React, { FunctionComponent } from 'react';
import { Switch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import { IRoute } from './Routes';

interface IProps {
    routes: IRoute[];
}

const Router: FunctionComponent<IProps> = ({ routes }) => {
    return (
        <Switch>
            {routes.map((route: IRoute) => (
                <ProtectedRoute key={route.path} {...route} />
            ))}
            <Route component={() => <Redirect to='/404' />} />
        </Switch>
    );
};

export default Router;
