import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
import { capitalize } from '../util/helpers';

type Props = {
    pages: Page[];
};

export type Page = {
    title: string;
    url: string;
};

export const Header: FC<Props> = (props) => {
    const { logout } = useAuth();
    const { pages } = props;

    const linkProps = pages.map((page) => {
        const title = capitalize(page.title);
        return {
            component: RouterLink,
            to: page.url,
            title: title,
        };
    });

    return (
        <AppBar position='relative'>
            <Toolbar>
                <Link
                    underline='none'
                    color='textSecondary'
                    variant='h5'
                    component={RouterLink}
                    sx={{ flexGrow: 1, color: 'white' }}
                    to='/'
                >
                    TMS
                </Link>
                {linkProps.map((linkProp) => (
                    <Link
                        key={linkProp.title}
                        color='textSecondary'
                        variant='body1'
                        underline='hover'
                        sx={{
                            paddingLeft: 4,
                            paddingRight: 4,
                            color: 'white',
                        }}
                        {...linkProp}
                    >
                        {linkProp.title}
                    </Link>
                ))}
                <Button
                    variant='contained'
                    sx={{ color: 'black', backgroundColor: 'white' }}
                    onClick={logout}
                >
                    Logout
                </Button>
            </Toolbar>
        </AppBar>
    );
};
