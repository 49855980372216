
import React, { FC, Suspense, useCallback } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Loading } from '../components/Loading';
import { useAuth } from '../hooks/useAuth';
import { IRoute } from './Routes';

const ProtectedRoute: FC<IRoute> = (props) => {
    const { isAuthenticated } = useAuth();

    const render = useCallback(() => {
        if (props.redirect) {
            return <Redirect to={props.redirect} />;
        } else {
            if (props.private) {
                if (isAuthenticated === null) {
                    return <Loading />;
                } else if (isAuthenticated === true) {
                    return props.component && <props.component {...props} />;
                } else {
                    return <Redirect to='/login' />;
                }
            } else {
                return props.component && <props.component {...props} />;
            }
        }
    }, [isAuthenticated, props]);

    return (
        <Suspense fallback={props.fallback}>
            <Route path={props.path} exact={props.exact} render={render} />
        </Suspense>
    );
};

export default ProtectedRoute;
